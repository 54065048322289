<template>
  <div>
    <h1>{{ name }} {{ name2 }}</h1>
  </div>
</template>

<script>
import {SET_BREADCRUMB} from '@/core/services/store/breadcrumbs.module';

export default {
  name: 'Dashboard',
  data() {
    return {
      name: 'Dashboard',
      name2: this.$t('auth.Hello, {name}', {name: 'Thapakorn'}),
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{title: 'Dashboard'}]);
  },
};
</script>
